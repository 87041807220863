<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<!-- <ion-back-button :text="$isWeb.value ? 'Academy' : ''" slot="start" :defaultHref="{name: 'home'}" /> -->
				<ion-back-button text="" slot="start" :defaultHref="{name: 'home'}" />

				<ion-title>
					<ion-label v-if="course">{{ course.theme.name }}</ion-label>
				</ion-title>
				<!-- <ion-title>
					<ion-searchbar class="header-search web-version" placeholder="Vyhľadaj" show-cancel-button="never"></ion-searchbar>
					<ion-label v-if="course" class="mobile-version">{{ course.theme.name }}</ion-label>
				</ion-title> -->

				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header> 

		<ion-content>
			<w-loadable :items="course" skelet="spinner">
				<div class="course-info">
					<div class="container">
						<div class="d-flex width-100 ion-justify-content-between">
							<div class="course-title-wrapper d-flex pr-1">
								<img v-if="course.image?.path" :src="course.image?.path">
								<img v-else src="../../lib@enum/themes/img/placeholder-img.jpg">
								<h4 class="large mt-1">{{ course.name }}</h4>
							</div>

							<div class="icon-wrapper d-flex ion-justify-content-between">
								<ion-icon class="size-xl" @click.stop="isLoggedIn ? toggleBookmark() : $router.push({name: 'login'})" :color="course.bookmark_by_active_user ? 'secondary' : ''" :icon="course.bookmark_by_active_user ? bookmarks : bookmarksOutline"></ion-icon>
								<ion-icon class="size-xl" @click.stop="isLoggedIn ? toggleDone() : $router.push({name: 'login'})" :color="course.done_by_active_user ? 'success' : ''" :icon="course.done_by_active_user ? checkmarkDoneCircle : checkmarkDoneCircleOutline"></ion-icon>
							</div>
						</div>
						<p class="vhtml banner-description mb-0" v-html="course.description" />
					</div>
				</div>

				<w-loadable class="container" :items="course.lessons" skelet="none" messageEmpty="Neboli nájdené žiadne lekcie pre tento kurz">
					<div v-for="(lesson, index ) in course.lessons" :key="`lesson-${index}`" class="course-card mt-1"> 
						<div class="d-flex ion-justify-content-between ion-align-items-center mb-1">
							<h4 class="mb-0 mt-0">{{ index + 1 }}. {{ lesson.name }}</h4>
							<ion-icon v-if="lesson.video_tags" class="size-m video-upload-button" :icon="addOutline" @click="$router.push({name: 'upload-video', params: {tags: lesson.video_tags.join(',')}})" />
						</div>
						
						<p v-if="lesson.video_tags" class="task-tags">#{{ lesson.video_tags.join(", #") }}</p>
						<!-- <p v-else class="task-tags"></p> -->

						<div class="task-buttons-wrapper d-flex ion-justify-content-between ion-align-items-center mb-1">
							<div>
								<ion-icon class="size-l" @click.stop="isLoggedIn ? lessonBookmark(lesson.id) : $router.push({name: 'login'})" :color="lesson.bookmark_by_active_user ? 'secondary' : ''" :icon="lesson.bookmark_by_active_user ? bookmarks : bookmarksOutline"></ion-icon>
								<ion-icon class="size-l" :verified="!!lesson.verification_status" @click.stop="isLoggedIn ? lessonCheckmark(lesson.id) : $router.push({name: 'login'})" :color="lesson.done_by_active_user ? 'success' : ''" :icon="lesson.done_by_active_user ? checkmarkCircle : checkmarkCircleOutline"></ion-icon>
							</div>

							<div @click="isLoggedIn ? verifyLesson(lesson) : $router.push({name: 'login'})" class="d-flex task-verification ion-align-items-center" :class="{'verification-pending' : lesson.verification_status == 'pending', 'verification-done' : lesson.verification_status == 'verified', 'disabled' : !lesson.done_by_active_user}">
								<ion-icon class="size-l" :icon="schoolOutline"></ion-icon>
								<p v-if="!lesson.verification_status"><b>vyžiadať si overenie</b></p>
								<p @click="deleteVerification(lesson.id)" v-else-if="lesson.verification_status == 'pending'"><b>úloha čaká na overenie</b></p>
								<p v-else-if="lesson.verification_status == 'verified'"><b>úloha overená</b></p>
							</div>
						</div>
						
						<A-slider :lessons="lesson.video_lessons" redirectTo="lesson-feed" :params="{courseSlug:course.slug, lessonSlug: lesson.slug}"/>
					</div>

					<div v-if="Object.keys(course.video_lessons).length" class="course-card mt-1">
						<div class="d-flex justify-content-between align-items-center mb-1">
							<h4 class="mb-0">Course Videos</h4>
							<!-- <img fake class="icon-done" src="../course/img/done-icon.png"> -->
						</div>

						<A-slider :lessons="course.video_lessons" redirectTo="course-feed" :params="{courseSlug:course.slug}"/>
					</div>
				</w-loadable>

			</w-loadable>
		</ion-content>
	</ion-page>
</template>

<script>
import ASlider 			from '@/plugins/lib@course/course/components/a-slider.vue'
import wAxios 			from '@/plugins/w/axios'
import { mapGetters} 	from 'vuex'
import { addOutline, personCircleOutline, bookmarksOutline, bookmarks, checkmarkCircle, checkmarkCircleOutline, checkmarkDoneCircleOutline, checkmarkDoneCircle, schoolOutline, logInOutline } from 'ionicons/icons'

const api = {
	read: (slug) 				=> wAxios.get_auth_data(`v1/courses/${slug}`),
	verify: (id) 				=> wAxios.post_auth_data(`v1/lessons/${id}/verify`),
	userflag: (model, id, data)	=> wAxios.post_auth_data(`v1/userflag/${model}/${id}`, data),
	deleteVerification: (id) 	=> wAxios.delete_auth_data(`v1/lessons/${id}/verify`),
}

export default {
	components: {
		ASlider,
	},

	data() {
		return {
			addOutline,
			personCircleOutline,
			bookmarksOutline,
			checkmarkDoneCircleOutline,
			checkmarkDoneCircle,
			schoolOutline,
			bookmarks, 
			checkmarkCircle,
			checkmarkCircleOutline,
			logInOutline,

			course: null,
			lesson: null,
			disableBookmarks: false,
			disableDone: false,
			disableCheckmark: false,
			disableBookmark: false,
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
	},

	async ionViewWillEnter() {
		this.course = null

		try {
			this.course = await api.read(this.$route.params.courseSlug)
			console.log(this.course)
		} catch (error) {
			this.course = {error: error}
		}

		console.log(this.course)
	},

	methods: {
		async verifyLesson(lesson) {
			if (lesson.verification_status) return

			try {
				await api.verify(lesson.id)
				const targetLesson = this.course.lessons.find(l => l.id == lesson.id)
				targetLesson.verification_status = 'pending'
			} catch (error) {
				this.$wToast.error(error)
			}
		},

		async deleteVerification(id) {
			try {
				await api.deleteVerification(id)
				const targetLesson = this.course.lessons.find(l => l.id == id)
				targetLesson.verification_status = null
			} catch (error) {
				this.$wToast.error(error)
			}
		},

		async toggleBookmark() {
			// Fix to prevent new request before previous is finished
			if (this.disableBookmarks) return
			this.disableBookmarks = true

			const course = this.course
			const value = this.course.bookmark_by_active_user ? 0 : 1

			course.bookmark_by_active_user = value
			this.eventBus.emit('course:update', course)

			try {
				const data = {
					'type' : 'bookmark',
					'value': value
				}

				await api.userflag('course', course.id, data)
			} catch (error) {
				course.like_by_active_user = !value
				this.eventBus.emit('course:update', course)
				this.$wToast.error(error)
			}

			this.disableBookmarks = false
		},

		async toggleDone() {
			// Fix to prevent new request before previous is finished
			if (this.disableDone) return
			this.disableDone = true

			const course = this.course
			const value = this.course.done_by_active_user ? 0 : 1

			course.done_by_active_user = value
			
			try {
				const data = {
					'type' : 'done',
					'value': value
				}

				await api.userflag('course', course.id, data)
			} catch (error) {
				course.like_by_active_user = !value
				this.eventBus.emit('course:update', course)
				this.$wToast.error(error)
			}

			this.disableDone = false
		},

		async lessonCheckmark(lessonId) {
			// Fix to prevent new request before previous is finished
			if (this.disableCheckmark) return
			this.disableCheckmark = true

			const lesson = this.course.lessons.find(lesson => lesson.id == lessonId)
			const value = lesson.done_by_active_user ? 0 : 1
			console.log(lesson.verification_status)
			if (lesson.verification_status) return
			console.log(value)
			lesson.done_by_active_user = value
			
			try {
				const data = {
					'type' : 'done',
					'value': value
				}

				await api.userflag('lesson', lesson.id, data)
			} catch (error) {
				lesson.done_by_active_user = !value
				this.$wToast.error(error)
			}

			this.disableCheckmark = false
		},

		async lessonBookmark(lessonId) {
			// Fix to prevent new request before previous is finished
			if (this.disableBookmark) return
			this.disableBookmark = true

			const lesson = this.course.lessons.find(lesson => lesson.id == lessonId)
			const value = lesson.bookmark_by_active_user ? 0 : 1 

			lesson.bookmark_by_active_user = value
			
			try {
				const data = {
					'type' : 'bookmark',
					'value': value
				}

				await api.userflag('lesson', lesson.id, data)
			} catch (error) {
				lesson.like_by_active_user = !value
				this.$wToast.error(error)
			}

			this.disableBookmark = false
		},
	},
}
</script>

<style lang="sass" scoped>
.course-info
	background-color: white
	padding: 15px
	cursor: default

	.course-title-wrapper
		width: calc( 100% - 30px )
		h4
			width: calc( 100% - 85px )

	img
		border-radius: 8px
		overflow: hidden
		margin-right: 15px
		width: 85px
		height: 85px
	
	.icon-wrapper
		width: 25px
		height: 60px
		margin-top: 10px
		flex-direction: column

		ion-icon
			cursor: pointer

	.banner-description
		margin: 15px 0 0 5px

.course-card
	background: white
	padding: 15px 0
	
	h4
		padding-left: 15px
		color: var(--ion-color-primary)
		cursor: default

	ion-icon
		margin-right: 15px

	.video-upload-button
		cursor: pointer

	.task-tags
		padding: 0 15px 15px 
		opacity: .6
		font-size: 15px

.task-buttons-wrapper
	padding: 0 15px
	ion-icon
		margin: 0 5px
		cursor: pointer

		&[verified="true"]
			opacity: 0.5
			color: gray
			pointer-events: none
			cursor: default

.task-verification
	color: var(--ion-color-primary)
	cursor: pointer

	&.verification-pending
		color: orange

	&.verification-done
		color: green 

	p
		margin: 2px 0 0 5px
		color: inherit
		text-transform: uppercase

@media only screen and (min-width: 450px)
	.course-title-wrapper
		width: calc( 100% - 65px ) !important
	
	.icon-wrapper
		width: 60px !important
		height: 25px !important
		flex-direction: row !important
</style>