<template>
	<div class="slider">
		<div class="video-thumb" v-for="(video, videoIndex) in lessons" :key="`video-${videoIndex}`"
			@click="$router.push({name: redirectTo, params: {...params, videoId: video.id}})">

			<div class="thumb-wrapper">
				<img :src="video.content_thumbnail">

				<div class="overlay d-flex ion-align-items-end">
					<div class="video-stats d-flex ion-justify-content-between">
						<p class="small mb-0">{{ moment(video.created_at).format('DD.MM.YYYY') }}</p>

						<div class="d-flex ion-justify-content-between ion-align-items-center">
							<ion-icon :icon="eyeOutline"></ion-icon>
							<p class="small mb-0">{{ video.visits }}</p>
						</div>
					</div>
				</div>
			</div>

			<p class="mb-0">{{ video.name }}</p>
			<p class="mb-0">#{{ video.tags.join(", #") }}</p>

			<div class="creator d-flex">
				<!-- <img src="../img/avatar.png" alt=""> -->
				<p>{{ video.content_shot_author }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { eyeOutline } from 'ionicons/icons'
import moment from 'moment'

export default {
	props: {
		lessons: {
			type: Array,
			required: true
		},

		params: {
			type: Object,
			required: true
		},
		
		redirectTo: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			eyeOutline,
			moment: moment
		}
	},
}
</script>

<style lang="sass" scoped>
.video-thumb
	position: relative
	margin-right: 5px
	width: 142px 
	display: inline-block
	cursor: default

	p 
		max-width: 142px 
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
	
	.thumb-wrapper
		position: relative
		cursor: pointer

		img
			height: 212px
			width: 142px 
		
			object-position: 0 0
			border-radius: 4px

		.overlay
			height: 212px
			width: 142px 
			padding: 3px 8px

			position: absolute
			top: 0
			left: 0
			z-index: 1000

			background: linear-gradient(0deg, rgba(0,0,0,0.5550595238095238) 10%, rgba(255,255,255,0) 44%, rgba(255,255,255,0) 100%)

	.video-stats
		width: 100%
		z-index: 100
		color: white

		p
			color: inherit
			overflow: visible

		ion-icon
			width: 14px
			height: 14px
			margin: 0 3px
	
	.creator
		margin-top: 5px

		img		
			height: 18px
			width: 18px
			margin-right: 7px 

.slider
	width: 100%
	overflow-y: scroll
	white-space: nowrap
	padding: 10px 10px 10px 15px

	&::-webkit-scrollbar
		display: none
</style>